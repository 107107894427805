import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['stream_type', 'stream_type_media_file', 'stream_type_link', 'stream_type_key']
  check_stream_type() {
    if(this.stream_typeTarget.value  == 'File') {
      this.stream_type_media_fileTarget.classList.remove('hidden')
      this.stream_type_linkTarget.classList.add('hidden')
      this.stream_type_keyTarget.classList.add('hidden')
    } else if(this.stream_typeTarget.value == 'Youtube'){
      this.stream_type_media_fileTarget.classList.add('hidden')
      this.stream_type_linkTarget.classList.remove('hidden')
      this.stream_type_keyTarget.classList.add('hidden')
    } else if(this.stream_typeTarget.value == 'Zoom'){
      this.stream_type_media_fileTarget.classList.add('hidden')
      this.stream_type_linkTarget.classList.remove('hidden')
      this.stream_type_keyTarget.classList.add('hidden')
    } else if(this.stream_typeTarget.value == 'RTMP Server'){
      this.stream_type_media_fileTarget.classList.add('hidden')
      this.stream_type_linkTarget.classList.remove('hidden')
      this.stream_type_keyTarget.classList.remove('hidden')
    } else if(this.stream_typeTarget.value == 'Daily.co LIVE'){
      this.stream_type_media_fileTarget.classList.add('hidden')
      this.stream_type_linkTarget.classList.remove('hidden')
      this.stream_type_keyTarget.classList.add('hidden')
    } else if(this.stream_typeTarget.value == "LIVE(Beta) - Internal"){
      this.stream_type_media_fileTarget.classList.add('hidden')
      this.stream_type_linkTarget.classList.remove('hidden')
      this.stream_type_keyTarget.classList.add('hidden')
    }


  }

}
