// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "controllers";

import "@fortawesome/fontawesome-free/css/all";
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/scss/fontawesome";

import flatpickr from "flatpickr";
import "bootstrap";
import "../stylesheets/application"
import Inputmask from "inputmask";
import Swal from 'sweetalert2';
import 'js/sweetalert2.js';
import ClipboardJS from 'clipboard';



require('js/dropdown.js')
require('js/jquery.downCount.min')
require("video.js")
require("flatpickr")
require("flatpickr/dist/flatpickr.css")
require('js/validation.js')
require('js/password_show')
require('js/increment-value.js')
require('js/flipcard.js')


ActiveStorage.start()
Rails.start()


addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
  target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})

$(document).ready(function(){
    $('#myselection').on('change', function(){
    	var demovalue = $(this).val();
        $("div.myDiv").hide();
        $("#show"+demovalue).show();
    });
});

document.addEventListener("turbo:load", function() {

  Inputmask().mask(document.querySelectorAll("input"));

  flatpickr(".flatpickr", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",

    onReady: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', false);
    },
  });

  new ClipboardJS('.btn');
})


