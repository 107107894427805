

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['quantity', 'quantity_price_2', 'quantity_price_3', 'quantity_price_4', 'quantity_price_5', 'quantity_price_6']
  check_quantity() {


    if(this.quantityTarget.value  == 2 ) {
      this.quantity_price_2Target.classList.remove('hidden');
      this.quantity_price_3Target.classList.add('hidden');
      this.quantity_price_4Target.classList.add('hidden');
      this.quantity_price_5Target.classList.add('hidden');
      this.quantity_price_6Target.classList.add('hidden');
}
    else if(this.quantityTarget.value == 3){
      this.quantity_price_3Target.classList.remove('hidden')
      this.quantity_price_2Target.classList.add('hidden')
      this.quantity_price_4Target.classList.add('hidden')
      this.quantity_price_5Target.classList.add('hidden')
      this.quantity_price_6Target.classList.add('hidden')
}
    else if(this.quantityTarget.value == 4){
      this.quantity_price_4Target.classList.remove('hidden')
      this.quantity_price_2Target.classList.add('hidden')
      this.quantity_price_3Target.classList.add('hidden')
      this.quantity_price_5Target.classList.add('hidden')
      this.quantity_price_6Target.classList.add('hidden')
}
    else if(this.quantityTarget.value == 5){
      this.quantity_price_5Target.classList.remove('hidden')
      this.quantity_price_2Target.classList.add('hidden')
      this.quantity_price_3Target.classList.add('hidden')
      this.quantity_price_4Target.classList.add('hidden')
      this.quantity_price_6Target.classList.add('hidden')
}
    else if(this.quantityTarget.value == 6){
      this.quantity_price_6Target.classList.remove('hidden')
      this.quantity_price_2Target.classList.add('hidden')
      this.quantity_price_3Target.classList.add('hidden')
      this.quantity_price_4Target.classList.add('hidden')
      this.quantity_price_5Target.classList.add('hidden')
}

  }
}
