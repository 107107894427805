

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['quantity', 'quantity_price_20', 'quantity_price_30', 'quantity_price_40', 'quantity_price_50', 'quantity_price_60', 'total']
  check_quantity() {


    if(this.quantityTarget.value  == 20 ) {
      this.quantity_price_20Target.classList.remove('hidden');
      this.quantity_price_30Target.classList.add('hidden');
      this.quantity_price_40Target.classList.add('hidden');
      this.quantity_price_50Target.classList.add('hidden');
      this.quantity_price_60Target.classList.add('hidden');
}
    else if(this.quantityTarget.value == 30){
      this.quantity_price_30Target.classList.remove('hidden')
      this.quantity_price_20Target.classList.add('hidden')
      this.quantity_price_40Target.classList.add('hidden')
      this.quantity_price_50Target.classList.add('hidden')
      this.quantity_price_60Target.classList.add('hidden')
}
    else if(this.quantityTarget.value == 40){
      this.quantity_price_40Target.classList.remove('hidden')
      this.quantity_price_20Target.classList.add('hidden')
      this.quantity_price_30Target.classList.add('hidden')
      this.quantity_price_50Target.classList.add('hidden')
      this.quantity_price_60Target.classList.add('hidden')
}
    else if(this.quantityTarget.value == 50){
      this.quantity_price_50Target.classList.remove('hidden')
      this.quantity_price_20Target.classList.add('hidden')
      this.quantity_price_30Target.classList.add('hidden')
      this.quantity_price_40Target.classList.add('hidden')
      this.quantity_price_60Target.classList.add('hidden')
}
    else if(this.quantityTarget.value == 60){
      this.quantity_price_60Target.classList.remove('hidden')
      this.quantity_price_20Target.classList.add('hidden')
      this.quantity_price_30Target.classList.add('hidden')
      this.quantity_price_40Target.classList.add('hidden')
      this.quantity_price_50Target.classList.add('hidden')
}

  }
}
