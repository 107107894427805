document.addEventListener("turbo:load", function() {
    const card = document.querySelectorAll('.card1');
    const front = document.querySelectorAll('.card-flip .card-front');
    const back = document.querySelectorAll('.card-flip .card-back');
    const cardContainer = document.querySelectorAll('.card-container');

    for (let i = 0; i < card.length; i++) {
        card[i].addEventListener('click', function() {
            $('.card1').removeClass('flipped');
            if (back[i].classList.contains('flipped')) {
                back[i].classList.remove('flipped');
                front[i].style.transform = "rotateY(0deg)";
            } else {
                back[i].classList.add('flipped');
                front[i].style.transform = "rotateY(0deg)";

            }
        });
    }
});
