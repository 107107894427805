import Rails from "@rails/ujs";
import Swal from 'sweetalert2';
window.Swal = Swal;

window.Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

Rails.confirm = function (message, element) {
  const SwalWithBootstrap = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary mr-2',
      cancelButton: 'btn btn-outline-primary ml-2'
    },
    buttonsStyling: false
  })

  console.log('alert starting')

  SwalWithBootstrap.fire({
    html: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  })
  .then((result) => {
    if (result.value) {
      console.log('sweetalert finished')
      element.removeAttribute('data-confirm')
      element.click()
    }
  })
  console.log('alert ended')

}
//   const Toast = Swal.mixin({
//     toast: true,
//     position: 'top-end',
//     showConfirmButton: false,
//     timer: 3000,
//     timerProgressBar: true,
//     didOpen: (toast) => {
//       toast.addEventListener('mouseenter', Swal.stopTimer)
//       toast.addEventListener('mouseleave', Swal.resumeTimer)
//   }
// })
