import { Controller } from "stimulus"
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import Rails from "@rails/ujs";


export default class extends Controller {
  static targets = ['player']
  connect() {}
  initialize() {
    let videoPlayer = videojs(this.playerTarget, {
      controls: true,
      playbackRates: [0.5, 1, 1.5, 2],
      autoplay: false,
      fluid: true,
      liveui: true
    })
    videoPlayer.addClass('video-js')
    videoPlayer.addClass('vjs-big-play-centered')
    videoPlayer.hlsQualitySelector({ displayCurrentQuality: true})

    videoPlayer.on('play', function() {
      setInterval(() =>
        console.log("Time Tracking: ", videoPlayer.currentTime())
      , 5000)
    })

    videoPlayer.on('pause', function() {
      console.log("Time Tracking Paused: ", videoPlayer.currentTime())
    })

    videoPlayer.on('ended', function() {
      Rails.ajax({
        type: "get",
        url: '/stream_products/tracker',
        data: 'customer_id=${99}&product_id=123&current_position=20'
      })
    })
  }

  disconnect() {}

}